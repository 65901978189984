import React from "react";
import "./style.css";

interface Button2Props {
    content:string;
    onClick:any;
    style?:any;
}

const Button2 = ({content, onClick, style}:Button2Props) => {
    return (
        <div className="button-container">
            <button onClick = {onClick} style={style}>
                {content}
            </button>
        </div>
    );
}

export default Button2;