import React from "react";
import "./style.css";
import Container from "../../common/Container";

interface SpotifyPodcastPlayerProps {
    podcastId: string;
}

const SpotifyPodcastPlayer: React.FC<SpotifyPodcastPlayerProps> = ({podcastId}) => {
    const spotifyEmbedUrl = `https://open.spotify.com/embed/episode/${podcastId}?utm_source=generator&theme=0`;

    return (
        <>
            <iframe src={spotifyEmbedUrl} className="spotify-iframe"
                    allow="autoplay
                    clipboard-write;
                    encrypted-media;
                    fullscreen;
                    picture-in-picture"
                    loading="lazy">
            </iframe>
        </>
    );
}

export default SpotifyPodcastPlayer;