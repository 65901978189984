import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";

import { I18nextProvider } from "react-i18next";



import { HashRouter } from "react-router-dom";
// import 'antd/dist/antd.min.css';
import 'antd/dist/reset.css';

import Router from "./router";
import i18n from "./translation";
// import { StrictMode } from 'react';


// const container = document.getElementById('root');
const root = ReactDOM.createRoot(document.getElementById('root')!); // createRoot(container!) if you use TypeScript

const App = () => { 
  // useEffect(
  //   () => {
  //     fetch('https://radentscheid-goe.de/api/server.php')
  //   },[]
  // )
return (
    // <StrictMode>
      <HashRouter>
        <I18nextProvider i18n={i18n}>
          <Router />
        </I18nextProvider>
      </HashRouter>
    // </StrictMode>
);
}

root.render(<App/>);
