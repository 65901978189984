import React from "react";
import { Row, Col } from "antd";
import { Fade } from "react-awesome-reveal";
import "./style.css";
import { scrollTo } from "../../common/scrollTo";
import { Link } from "react-router-dom";
import FullWidthContainer from "../../common/FullWidthContainer";
import Button2 from "../../common/Button2";


const EasyOffers = () => {
    return (
        <div className = "easy-offers">
            <div className="offer">
                <h6>downloads.</h6>
                <p>Hier gibt's vollen Texte der beiden Begehren (Unterschriftenlisten).</p>
                <a onClick={() => scrollTo("downloads")}>Zu den Texten</a>
            </div>
            <div className="offer">
                <h6>radentscheid kompakt.</h6>
                <p>Der Web-Flyer fasst die Begehren einfach und knapp zusammen und erklärt alles Wichtige.</p>
                <Link to = {"flyer"}><a>Zum Web-Flyer</a></Link>
            </div>
            <div className="offer">
                <h6>über uns.</h6>
                <p>GöttingenZero organisiert den Radentscheid. Mehr zu uns erfährst du auf unserer Website.</p>
                <a href = "https://www.goettingenzero.de/" target="_blank">Zur GöttingenZero Website</a>
            </div>
        </div>
    )
};

const LinksEn = () => {
    return(
        <div style={{width: "100%", justifyItems: "left", backgroundColor: "rgba(255, 255, 255, 0.571)", padding: "1em"}}>
            <ul>
                <li><Link to = "../flyer-en">Web-Flyer (english version)</Link></li>
            </ul>
        </div>
    )
}
interface IntroNeuProps {
    language: string;
}
const IntroNeu = ({language}: IntroNeuProps) => {
    const l = (de:string, en:string) => {
        return(language === "en" ? de : en)
    }
    return(
            <Fade>
                <FullWidthContainer>
                <Row id="intro">
                    <div className="intro-neu-wrapper">
                        <div className="vertical-flex">
                            <div className="horizontal-flex">
                                <h1>Radentscheid</h1>
                                <h1>Göttingen</h1>
                                {language === "en" ? <h3>English Summary</h3>: <></>}
                                { language === "en" ? <LinksEn/>:<EasyOffers/>}
                            </div>
                            <div className="img-wrapper">
                            <img src = "/img/svg/logo-radentscheid-clean.svg"/>
                            </div>
                        </div>

                    </div>
                    </Row>
                    </FullWidthContainer>
            </Fade>
    )
}

export default IntroNeu;