import React, {useState, useEffect} from "react";
import { Fade } from "react-awesome-reveal";
import './index.css';

import Container from "../../../../common/Container";
import FullWidthContainer from "../../../../common/FullWidthContainer";
import {CatSlider, CustDropdown, NumSlider} from "../../../../common/Slider";
import Eingabe from "./eingabe";
import Ausgabe from "./ausgabe";

import { submitValueProps } from "./eingabe";

interface CustomAdvProps {
    backToSelection?: React.Dispatch<React.SetStateAction<string>>;
}
const CustomAdv = ({backToSelection}:CustomAdvProps) => {
    const [submitted, updateSubmitted] = useState<Number>(0);
    const [submitValue, updateSubmitValue] = useState<submitValueProps>();

    const weiche = (submittedVal: Number) => {
        if (submittedVal === 0) {
            return(<Eingabe
                updateSubmitValue={updateSubmitValue}
                updateSubmitted={updateSubmitted}
            />)
        } else {
            return(<Ausgabe 
                submittedValue={submitValue}
                backToSelection={backToSelection !== undefined ? backToSelection : undefined}
                />)
        }
    }

    return (
        <Fade>
            <FullWidthContainer>
                <div className="custom-adv-background">
                <Container>
                    <div className="custom-adv-wrapper">
                        <div className="header">
                            <h1>Rad-O-Mat</h1>
                            <div className="sub">
                                <div className="el">
                                    <h3>Beantworte uns ein paar Fragen und erfahre,
                                        welchen Mehrwert der Radentscheid für dich ganz persönlich bringt.</h3>
                                </div>
                                <div className="el">
                                    <img src = './img/svg/fahrrad-icon.svg' alt = 'Bild Fahrrad Icon'/>
                                </div>
                            </div>
                        </div>
                        {weiche(submitted)}
                    </div>
                </Container>
                </div>
            </FullWidthContainer>
        </Fade>
    );
}

export default CustomAdv;