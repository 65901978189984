import React, {lazy} from "react";
import { Row,} from "antd";
import { Fade } from "react-awesome-reveal";
import "./style.css";
import Flyer from "../../components/Flyer";
import { PngIcon } from "../../common/PngIcon";
import Container from "../../common/Container";
import ScrollToTop from "../../common/ScrollToTop";
import FlyerEn from "../../components/FlyerEn";


const FlyerPageEn = () => {
    return(
        <Container>
        <ScrollToTop/>
        <Row id = 'intro'></Row>
            <div className="flyerpage-wrapper">
                <div id='top'/>
                <div className="intro">
                    <div className="left">
                        <div className="text">
                            <h1>Web-Flyer (english version)</h1>
                            <h2>
                                The "Radentscheid" brief and colorful.
                            </h2>
                        </div>
                    </div>
                    <div className="right">
                        <img
                            src="./img/png/_radentscheid-logo-14-04-2023.jpg"
                            alt="radentscheid-logo"
                        />
                    </div>
                </div>
                <FlyerEn/>
            </div>
        </Container>
    )
}

export default FlyerPageEn;