import React from "react";

import "./index.css";
import { Fade } from "react-awesome-reveal";
import ScrollToTop from "../../common/ScrollToTop";
import { Row } from "antd";
import { PngIcon } from "../../common/PngIcon";
import SectionLine from "../SectionLine";
import Button2 from "../../common/Button2";
import YouTube from "react-youtube";

interface AntwortVideoProps {
    frage:string;
    videoid:string;
}

const AntwortVideo = ({frage, videoid}:AntwortVideoProps) => {
    return(
        <div className="video-wrapper">
            <h6>{frage}</h6>            
            <div className="youtube-wrapper">
                <div className="video">
                    <YouTube style = {{width: "100%", maxWidth: "100%"}}
                        className="video"
                        videoId={videoid}
                    />
                </div>
            </div>
            
        </div>
    )
}


const VideoAntworten = () => {
    return (
        <div className="video-antworten-wrapper">
            <Fade>
                <ScrollToTop/>
                <Row id = 'intro'></Row>
                <div className="faqpage-wrapper">
                    <div id='top'/>
                    <div className="intro">
                        <div className="left">
                            <h1>Eure Fragen</h1>
                            <h2>
                                Antworten zu von euch gestellen Fragen.
                            </h2>
                            <a
                                href=""
                                target="_blank"
                            >
                            <Button2
                                content="Eigene Frage einreichen"
                                onClick={() => {}}
                            />
                    </a>
                        </div>
                        <div className="right">
                            <PngIcon
                                src="_radentscheid-logo-14-04-2023.jpg"
                                width="250px"
                                height="fit-content"/>
                        </div>
                    </div>
                    <SectionLine how="right"/>
                    <h6 style = {{marginTop: "2em"}}
                    >Dieser Teil der Website steht erst in Kürze vollständig zur Verfügung.</h6>

                    <div className="video-grid">
                        <AntwortVideo
                            frage = {"Radentscheid Image Film"}
                            videoid={"sNvWThUery0"}
                        />
                        <AntwortVideo
                            frage = {"Radentscheid Göttingen | GöttingenZero handelt - Selbstwirksam die Zukunft gestalten (Teil2)"}
                            videoid={"Inb0vaHeax4"}
                        />
                    </div>

                    <SectionLine how="center"/>
                    <div className="noch-nicht-gestellt">
                    <h6>Deine Frage ist noch nicht gestellt? </h6>
                    <p>Dann klicke oben auf den Button "Eigene Frage einreichen" und hole dies direkt nach.</p>
                    </div>
                    <SectionLine how="center"/>


                    
                </div>
            </Fade>
        </div>
    );

}

export default VideoAntworten;