import React from "react";
import "./style.css";
import SectionLine from "../SectionLine";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';



const FlyerEn = () => {
    return(
        <div className="flyer">
            
                <SectionLine how="center"/>
            <div className="section">
                <div className="header">
                    <h1>What's the "Radentscheid"?</h1>
                    <img src = "./img/png/zwei-kreuze.png" alt = ""/>
                </div>
               
                <p>
                    The Göttingen "Radentscheid" is a project based on <b>two citizen initiatives</b>
                    that advocate for improving bicycle traffic in the city.<br/>
                    Since 8,400 valid signatures were collected for both initiatives, an election on the "Radentscheid" will take on June 9, 2024.
                </p>
            </div>   
            <SectionLine how = "center"/>
            <div className="section">   
                <h1>Why the effort?</h1>
                <p>
                    GöttingenZero as initiator strives for the city's climate neutrality. <br/>
                    In the field of mobility, the city has the greatest potential for action - and does the least.
                </p>
                <div className="float-container">
                    <img src ="./img/png/verkehrschaos-gosslerstrasse.png" alt = ""/>
                </div>
                
                <p className="img-subscription">
                    Streets designated for bicycles should not look like this example on Goßlerstraße.
                </p>
            </div>    
            <SectionLine how = "center"/>
            <div className="section">   
                <div className = "header">
                    <h1>Two Citizens' Initiatives to make a true difference</h1>
                    <img src = "./img/png/zwei-kreuze.png"/>
                </div>
                <p>
                    The <button className = "green">first initiative</button> contains various general aims to support local bicycle traffic.
                    It's a colourful mix of general strategies and contributing demands.
                </p>
                <div className="float-container">
                    <img src = "./img/png/schilder.png"/>
                </div>
                <p>
                    However, such targets and strategies are always a little abstract.
                    Therefore, the second initiative names concrete roads, where cost-efficient measures can make the greatest contribution.
                </p>
                
                <p>
                    The <button className = "blue">second initiative</button> 
                    ensures that the gaps in the network will be minimized until 2030
                    and the most effective projects in the core city will be prioritized on the time schedule.  
                </p>
            </div>   
            <SectionLine how = "right"/>
            <div className="section">  
                <h1>What are the main goals?</h1>
                <p>
                    <b>More and sufficiently wide bike lanes </b> are needed to enable
                     secure and komfortable bicycle use - especially for children and elderly people.
                </p>
                
                <p id = "highlight">
                    For this, a <b>physical separation</b> and unbundling of motorized and bicycle traffic is needed.
                </p>
                
                <div className="float-container">
                    <img src = "./img/png/protected-bike-lane-icon.png" alt = "protected-bike-lane-picto"/>
                </div>
                
                <p>
                    In wide areas of the Göttinger cycling network you cannot ride securely.
                    There are huge gaps between the city districts.
                </p>
                <p id = "highlight">
                    The "Radentscheid" wants to <a>close these gaps</a>.
                </p>
                {/* Gegenüberstellung Radnetze ergänzen */}
            </div>
            <SectionLine how="center"/>
            <div className="section">
                <h1>How are these goals achieved?</h1>
                <p>The objectives will be achieved through the following key measures:</p>
                <ul id = "highlight">
                    <li>
                        <b>Separation</b> of bikes and cars on busy roads.
                    </li>
                    <li>
                    <b>Protected Bike Lanes</b> as safe and protected spaces for cycling.
                    </li>
                    <li>
                    Development of concepts for <b>one-way streets</b>.
                    </li>
                    <li>
                    Attractive design of <b>bicycle streets</b>.
                    </li>
                </ul>
                <p id = "highlight">
                    <b>Protected Bike Lanes</b> separate streets such as Merkelstraße or Rosdorfer Weg in the middle:
                    Bicycles here, cars there, with a barrier in between.
                </p>
                <p>This is what it looks like in Darmstadt:</p>
                <div className="float-container">
                <img src ="./img/png/protected-bike-lane-darmstadt.png"/>
                </div>
                <p>This means that people of all ages can cycle safely and securely.</p>
            </div>
            <SectionLine how="center"/>
        </div>
    )
}

export default FlyerEn;