import React, { useState } from 'react';
import './index.css';
// import Button2 from '../Button2';

import type { MenuProps } from 'antd';
import { Button, Dropdown } from 'antd';



interface DropdownProps {
  categories: string[];
  value: string;
  onChange: any;
  buttonLabel:string
}

export const CustDropdown = ({buttonLabel, categories, value, onChange}:DropdownProps) => {
  const [sel, updateSel] = useState("Wähle deinen Stadtbezirk ...");

  const items: MenuProps['items'] = categories.map(
      c => {return(
        {
        key: c, 
        label: (<a style = {c === value ? {fontWeight: 'bold'} : {fontWeight:'normal'}} onClick={() => {onChange(c); updateSel(c)}}>{c}</a>)
      }
      )}
    )


  return(
    <div className='dropdown-container'>
      <Dropdown menu={{ items }} placement="bottomLeft" arrow>
        <button id={sel !== "Wähle deinen Stadtbezirk ..." ? 'on' : 'off'}>
          {sel}
        </button>
      </Dropdown>
    </div>
  );

}


interface CatSliderProps {
  categories: string[];
  value: string;
  onChange: any;
}

export const CatSlider = ({categories, value, onChange}: CatSliderProps) => {
  return(
    <div className='catslider-container'>
      {
        categories.map(
          c => {return(
          <div className='cat'>
            <button 
              onClick={()=>{onChange(c)}}
              id = {value === c ? 'on' : 'off'}
            >
              {c}
            </button>
            </div>
            )
          }
        )
      }
    </div>
  )
}



interface NumSliderProps {
  min: number;
  max: number;
  value: number;
  onChange: any;
}

export const NumSlider: React.FC<NumSliderProps> = ({ min, max, value, onChange }) => {
  return (
    <div className='slidecontainer'>
        <input type="range" min={min} max={max} value={value} onChange={(e) => onChange(Number(e.target.value))} className="slider"/>
    </div>
  );
};
