import React, { useState, useEffect, PureComponent } from "react";
import { withTranslation } from "react-i18next";

import Chart from "react-apexcharts";

// import { Container, DashboardContainer} from "./styles";
import "./style.css";
import FullWidthContainer from "../../common/FullWidthContainer";


interface TimeChartData {
  name: string;
  datum: number;
  Unterschriften100: number;
  Unterschriften80: number;
}


const TimeChart = () => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'area',
      zoom: {
        enabled:false
      }
    },
    dataLabels: {
      enabled: false
    },
    // title: {
    //   text: 'Entwicklung des Sammelfortschritts',
    //   align: 'left'
    // },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      categories: ['2023-09-01',
      '2023-09-11',
      '2023-09-18',
      '2023-09-24',
      '2023-10-01',
      '2023-10-08',
      '2023-10-15',
      '2023-10-22',
      '2023-10-29',
      '2023-11-01',
      '2023-11-02',
      '2023-11-03',
      '2023-11-04',
      '2023-11-05',
      '2023-11-06',
      '2023-11-07',
      '2023-11-08',
      '2023-11-09',
      '2023-11-10',
      '2023-11-12',
      '2023-11-13',
      '2023-11-15',
      '2023-11-16',
      '2023-11-17',
      '2023-11-18',
      '2023-11-19',
      '2023-11-20',
      '2023-11-21',
      '2023-11-22',
      '2023-11-23',
      '2023-11-24',
      '2023-11-25',
      '2023-11-26',
      '2023-11-27',
      '2023-11-28',
      '2023-11-29',
      '2023-11-30',
      '2023-12-01',
      '2023-12-02',
      '2023-12-03',
      '2023-12-04',
      '2023-12-05',
      '2023-12-06',
      '2023-12-07',
      '2023-12-08',
      '2023-12-09',
      '2023-12-10',
      '2023-12-11',
      '2023-12-12',
      '2023-12-13',
      '2023-12-14',
      '2023-12-15',
      '2023-12-16',
      '2023-12-17',
      '2023-12-18',
      '2023-12-19',
      '2023-12-20',
      '2023-12-21',
      '2023-12-22',
      '2023-12-23',
      '2023-12-24',
      '2023-12-25',
      '2023-12-26',
      '2023-12-27',
      '2023-12-28',
      '2023-12-29',
      '2023-12-30',
      '2023-12-31',
      '2024-01-01',
      '2024-01-02',
      '2024-01-03',
      '2024-01-04',
      '2024-01-05',
      '2024-01-06',
      '2024-01-07',
      '2024-01-08',
      '2024-01-09',
      '2024-01-10',
      '2024-01-11',
      '2024-01-12',
      '2024-01-13',
      '2024-01-14',
      '2024-01-15',
      '2024-01-16',
      '2024-01-19',
      '2024-01-20',
      '2024-01-21',
      '2024-01-22',
      '2024-01-23',
      '2024-01-24',
      '2024-01-25',
      '2024-01-26',
      '2024-01-27',
      '2024-01-28',
      '2024-01-29',
      '2024-01-30',
      '2024-01-31',
      '2024-02-01',
      '2024-02-02',
      '2024-02-03',
      '2024-02-04',
      '2024-02-05',
      '2024-02-06',
      '2024-02-07',
      '2024-02-08',
      '2024-02-09',
      '2024-02-10',
      '2024-02-12'],
    },
    yaxis: {
      min: 0,
      max: 9500
    },
    colors: 
      ['#bac2cf','green','blue'],
    fill: {
      type: 'solid',
      colors: ['#ffffff','green','#FFC80C'],
      opacity: [0, 0.1, 0.7]
    }
  };
  
  const series = [
    {
        name: 'erforderliche gültige Unterschriften je Begehren',
        data: [6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813,
          6813],
      },
    { 
      name: 'Begehren 1',
      data: [4,
        74,
        468,
        743,
        865,
        1097,
        1377,
        1623,
        1833,
        2004,
        2142,
        2270,
        2325,
        2474,
        2567,
        2614,
        2696,
        2723,
        2829,
        3059,
        3096,
        3173,
        3266,
        3378,
        3411,
        3505,
        3563,
        3608,
        3719,
        3864,
        3913,
        3983,
        4144,
        4204,
        4255,
        4274,
        4391,
        4419,
        4437,
        4588,
        4628,
        4698,
        4747,
        4808,
        4907,
        4951,
        5150,
        5191,
        5226,
        5307,
        5446,
        5532,
        5561,
        5637,
        5737,
        5763,
        5822,
        5963,
        6008,
        6346,
        6351,
        6351,
        6378,
        6429,
        6447,
        6455,
        6530,
        6565,
        6641,
        6656,
        6672,
        6688,
        6742,
        6737,
        6914,
        6944,
        6962,
        7012,
        7054,
        7126,
        7126,
        7294,
        7297,
        7350,
        7345,
        7345,
        7580,
        7583,
        7593,
        7630,
        7655,
        7696,
        7679,
        7949,
        7947,
        8000,
        8079,
        8145,
        8233,
        8661,
        8544,
        8569,
        8576,
        8574,
        8725,
        8837,
        9028,
        9217],
    },
    {
      name: 'Begehren 2',
      data: [4,
        71,
        452,
        718,
        837,
        1038,
        1326,
        1539,
        1772,
        1954,
        2088,
        2221,
        2275,
        2421,
        2524,
        2569,
        2642,
        2673,
        2760,
        2963,
        3002,
        3087,
        3166,
        3291,
        3318,
        3392,
        3461,
        3509,
        3629,
        3756,
        3825,
        3894,
        4018,
        4079,
        4132,
        4152,
        4256,
        4286,
        4316,
        4462,
        4504,
        4581,
        4628,
        4689,
        4790,
        4837,
        5037,
        5080,
        5120,
        5196,
        5355,
        5439,
        5472,
        5551,
        5649,
        5679,
        5745,
        5880,
        5928,
        6259,
        6265,
        6265,
        6293,
        6344,
        6361,
        6370,
        6450,
        6494,
        6548,
        6563,
        6581,
        6593,
        6637,
        6633,
        6806,
        6823,
        6842,
        6895,
        6933,
        7005,
        7005,
        7164,
        7167,
        7222,
        7218,
        7218,
        7460,
        7464,
        7476,
        7517,
        7540,
        7584,
        7569,
        7848,
        7843,
        7897,
        7973,
        8043,
        8130,
        8575,
        8484,
        8506,
        8513,
        8537,
        8698,
        8809,
        8997,
        9183]
    }
  ];
    return (
      <div className="graph-wrapper">
        <Chart options={options} series={series} type="area" height={350} />
      </div>
    );
}



interface DashboardProps {
  lang: string;
}
const Dashboard = ({lang}:DashboardProps) => {
  const l = (de:string, en:string) => {return(lang === "en" ? en : de)} 
    return (
        <FullWidthContainer>
        <div className = "dashboard-wrapper" id = 'dashboard'>
          <div className = "content-wrapper">
            {/* <hr/> */}
            <h1>{l("Die Unterschriftensammlung im Rückblick", "Collection of signatures in retrospect")}</h1>
            <h4> 
              {l("Mehr als 9.000 Unterstützer:innen ermöglichen den Bürgerentscheid am 09. Juni 2024.",
              "More than 9,000 supporters make the referendum on June 9th 2024 possible.")}
            </h4> 
            <div className="timechart-wrapper">
              <TimeChart/>
            </div>
            <div  className = 'subtext' >
              <ul>
                <li>
                  {l("Je Begehren mussten mindestens 6.813 gültige Unterschriften vorliegen, damit der Bürgerentscheid stattfindet.",
                  "At least 6,813 valid signatures per petition were required for the referendum to take place.")}
                  
                </li>
                <li>
                  {l(
                    "Durch die Stadtverwaltung wurden je Begehren mehr als 8.300 gültige Unterschriften bestätigt.\n Das sind knapp 10 % der wahlberechtigten Göttinger Bevölkerung.",
                    "The city administration confirmed more than 8,300 valid signatures per petition.\n That is just under 10% of Göttingen's eligible voters."
                    )}
                </li>
                <li>
                  {l("Der Bürgerentscheid findet am 09. Juni 2024 parallel zur Europawahl statt. Notwendig ist dort eine Mehrheit von 50,01% der Stimmen für jedes der beiden Begehren.",
                  "The referendum will take place on June 9th 2024 in parallel with the European elections. A majority of 50.01% of votes is required for each of the two referendums.")}
                  
                </li>
              </ul>
            </div>
          </div>

        </div>
        </FullWidthContainer>

    );
}

export default withTranslation()(Dashboard);
