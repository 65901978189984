import React, {useState} from "react";
import "./style.css";


import supporters from "./supporters.json";
import Container from "../../common/Container";

interface KachelProps {
    name:string;
    web?:string;
    img?:string;
}
const Kachel = ({name ,web, img}:KachelProps) => {
    if (typeof(web) !== undefined) {
        return(
            <a href = {web} target="_blanc">
                <div className = "kachel">
                    <h6>{name}</h6>
                    <div className="img-container">
                        <img src = {`./img/logos-supporters/${typeof(img) !== undefined ? img : "kein_logo.png" }`}/>
                    </div>
                </div>
            </a>
        );
    } else {
        return(
            <div className = "kachel">
                <h6>{name}</h6>
                <img src = {`./img/logos-supporters/${typeof(img) !== undefined ? img : "kein_logo.png" }`}/>
            </div>
        );
    }
}
interface SupporterProps {
    lang: string;
}
const Supporter = ({lang}:SupporterProps) => {
    function shuffle(array: KachelProps[]) {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle.
        while (currentIndex > 0) {
      
          // Pick a remaining element.
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }

    const l = (de:string, en:string) => {return(lang === "en" ? en : de)} 
    return(
        <Container>
        <div className="supporter-container">
            <h1>{l("Unterstützer:innen", "Supporters")}</h1>
            <p>{l("Unter anderem ...", "Among others ...")}</p>
            <div className="kachel-container">
            {
                shuffle(supporters).map(s => {
                    return(<Kachel name = {s.name} web={s.web} img = {s.img}/>)
                })
            }
            </div>
            <p id = "remark">Dein(e) Verein / Geschäft / Intiative soll auch mit aufgeführt werden? <br/>
                Sende uns einfach eine <a href = "mailto:goettingenzero@posteo.de">E-Mail</a>.</p>
            
        </div>
        </Container>
    )
}

export default Supporter;