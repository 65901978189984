import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import "./style.css";

import { Link } from 'react-router-dom';
import {HashLink} from "react-router-hash-link";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  Large,
} from "./styles";

const Footer = ({ t }: any) => {
  // const handleChange = (language: string) => {
  //   i18n.changeLanguage(language);
  // };

  // const stylist = {
  //   fontSize: "large",
  //   padding: "5px",
  //   fontColor: "#000000",
  //   marginTop: "2px"
  // };

  return (
      <FooterSection>
          <div className="footer-wrapper">
            <div className="element left">
              <h1>Kontakt</h1>
              <p>
                Mail: <a href = "mailto:goettingenzero@posteo.de">goettingenzero@posteo.de</a><br/>
                Telefon: <a href = "tel:+49 176 70038466">+49 176 70038466</a><br/>
                Instagram: <a href = "https://www.instagram.com/goettingenzero/" target="_blank">@goettingenzero</a><br/>
              </p>
              <h1>Mehr über GöttingenZero</h1>
              <p>
                <a href = "https://www.goettingen-klimaneutral.de" target="_blank"> goettingen-klimaneutral.de </a>
              </p>
            </div>
            <div className="element right">
              <HashLink to ="./news#top"><h1>Presse und Aktuelles</h1></HashLink>
              <HashLink to ="./gute-gruende#top"><h1>Gute Gründe für den Radentscheid</h1></HashLink>
              <HashLink to ="./impressum#top"><h1>Impressum</h1></HashLink>
              <HashLink to ="./datenschutz#top"><h1>Datenschutz</h1></HashLink>
            </div>
          </div>
      </FooterSection>
  );
};

export default Footer;


          {/* <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
            <Title>Kontakt</Title>
              <div style = {stylist}>
                <ul>
                  <li>
                    Mail: <a href = "mailto:goettingenzero@posteo.de">goettingenzero@posteo.de</a>
                  </li>
                  <li>
                    Phone: <a href = "tel:+49 176 70038466">+49 176 70038466</a>
                  </li>
                  <li>
                    Instagram: <a href = "tel:+49 176 70038466">@goettingenzero</a>
                  </li>
                </ul>
                  
              </div>
            </Col>
            <Col lg={10} md={10} sm={12} xs={12}>
              <Title>GöttingenZero</Title>
              <Large left="true" to="/">
                <a href = "https://www.goettingen-klimaneutral.de"> goettingen-klimaneutral.de </a>
              </Large>
            </Col>
            </Row>
            <Row justify="space-between">
              <Col lg={10} md={10} sm={12} xs={12}>
                <Title>Impressum</Title>
                <Large left = "true">
                  <Link to = "/impressum">zum Impressum</Link>
                </Large>
              </Col>
              <Col lg={10} md={10} sm={12} xs={12}>
                <Title>Datenschutz</Title>
                <Large left = "false">
                  <Link to = "/datenschutz">zur Datenschutzerklärung</Link>
                </Large>
              </Col>
            </Row> */}