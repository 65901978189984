import React, {useState, useEffect} from "react";

import "./index.css";
import Button2 from "../../common/Button2";
import Container from "../../common/Container";
import axios from "axios";
import { Link } from "react-router-dom";

const NewsletterAbmeldung = () => {
    const [mail, setMail] = useState<string>("");
    const [color, setColor] = useState<string>("gray");

    const [state, setState] = useState<string>("eingabe");
    const [response, setResponse] = useState<string>("nothing");

    const checkState = (s:string) => {
        if (s === ""){
            setColor('gray')
        } else if (s.includes("@") === false) {
            setColor('red');
        }
        else {
            setColor('green');
        }       
    }

    const handleSubmit = () => {
        if (mail.includes("@") === false) {
            setColor('red');
        } else {
            setColor('green');
            const url = "https://radentscheid-goe.de/api/newsletter-abmelden.php";

            let fData = new FormData();   
            fData.append('mail', mail);

            axios.post(url, fData)
            .then(
                response => setResponse(response.data)
            )
            .catch(
                error => {console.log(error); setState("fehler");}
            )
        }
    };

    // triggered on change of response
    useEffect(
        () => {
            if (response === "nothing"){
                setState("eingabe");
            } else if (response === "SUCCESS") {
                setState("abgeschlossen");
            } else if (response === "ERROR"){
                setState("fehler");
            } else {
                setState("eingabe");
            }
        }, [response]
    )


    const Anmeldung = <>
        <div>
            <p>
                Hier kannst du dich vom Newsletter abmelden.<br/>
                Gib einfach deine E-Mail-Adresse ein und klicke auf abmelden.    
            </p>
            <input 
                about="text" 
                value = {mail} 
                onChange = {(e) => {
                    checkState(`${e.target.value}`);
                    setMail(`${e.target.value}`);
                }}
                style = {{border: `solid thick ${color}`}}
            />
        </div>
        <Button2
            content="abmelden"
            onClick={handleSubmit}
        />
        </>

    const Erfolg = <>
        <div className="erfolg">
                <p>Du wurdest erfolgreich aus dem Verteiler des Newsletters entfernt (vorausgesetzt die eingebene E-Mail Adresse war korrekt).</p>
        </div>
        <div>
        <Link to = "../anmeldung-newsletter"><Button2 content="Hier kannst du dich wieder anmelden" onClick={() => {}}/></Link>
        </div>
    </>

    const Fehler = <>
        <div className="fehler">
                <p>Das hat leider nicht geklappt! Probiere es gleich noch einmal oder schreibe uns eine E-Mail wir kümmern uns darum.</p>
        </div>
        <Button2
            content = "Neu eingeben"
            onClick={() => {setState("eingabe")}}
        />
    </>


    return (
        <Container>
        <div className="newsletter-wrapper">
            
            <h1>Abmeldung vom Newsletter</h1>

            {
                state === 'eingabe' ? Anmeldung : (state === 'abgeschlossen' ? Erfolg : Fehler)
            }
        </div>
        </Container>
    )
}

export default NewsletterAbmeldung;