import React from "react";
import YouTube from 'react-youtube';

import "./style.css";
import Container from "../../common/Container";

interface YouTubePlayerProps {
    ytId: string;
}

const YoutubeVideo: React.FC<YouTubePlayerProps> = ({ytId}) => {
    return (
        <Container>
            <div className="youtube-wrapper">
                <div className="video">
                    <YouTube style = {{width: "100%", maxWidth: "100%"}}
                        className="video"
                        videoId={ytId}
                    />
                </div>
            </div>
        </Container>
    );
}

export default YoutubeVideo;