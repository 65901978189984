import React from "react";
import "./style.css";
import SectionLine from "../SectionLine";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';



const Flyer = () => {
    return(
        <div className="flyer">
            
                <SectionLine how="center"/>
            <div className="section">
                <div className="header">
                    <h1>Was ist der Radentscheid?</h1>
                    <img src = "./img/png/zwei-kreuze.png" alt = ""/>
                </div>
               
                <p>
                    Der Göttinger Radentscheid ist ein Projekt aus <b>zwei Bürgerbegehren</b>, 
                    die sich für eine Verbesserung des Fahrradverkehrs in der Stadt stark machen.<br/>
                    Da jeweils 8.400 gültige Unterschriften für beide Begehren gesammelt wurden, kann am 09.Juni 2024
                    über den Radentscheid an der Wahlurne entschieden werden.
                </p>
            </div>   
            <SectionLine how = "center"/>
            <div className="section">   
                <h1>Warum der Einsatz?</h1>
                <p>
                    GöttingenZero möchte, dass die Stadt klimaneutral wird.<br/>
                    Im Sektor Verkehr hat die Stadt den größten Handlungsspielraum - und tut am wenigsten.
                </p>
                <div className="float-container">
                    <img src ="./img/png/verkehrschaos-gosslerstrasse.png" alt = ""/>
                </div>
                
                <p className="img-subscription">
                    So wie hier die Goßlerstraße sollte keine Fahrradstraße aussehen.
                </p>
            </div>    
            <SectionLine how = "center"/>
            <div className="section">   
                <div className = "header">
                    <h1>Zwei Bürgerbegehren - damit sich auch was tut</h1>
                    <img src = "./img/png/zwei-kreuze.png"/>
                </div>
                <p>
                    Das <button className = "green">erste Begehren</button> enthält viele 
                    allgemeine Ziele zur Förderung des Radverkehrs. Es ist ein bunter Mix aus 
                    Strategien und sinnvollen Forderungen.
                </p>
                <div className="float-container">
                    <img src = "./img/png/schilder.png"/>
                </div>
                <p>
                    Solche Ziele lassen sich aber nur relativ schwammig formulieren.
                    Deswegen gibt es das zweite Bürgerbegehren, das konkret die Straßen benennt, 
                    wo etwas getan werden soll.
                </p>
                
                <p>
                    Das <button className = "blue">zweite Begehren</button> stellt sicher, dass die Lücken im Netz
                    hinterher auch wirklich kleiner werden und die wichtigen Projekte in der Kernstadt zuerst angegangen werden.
                </p>
            </div>   
            <SectionLine how = "right"/>
            <div className="section">  
                <h1>Was sind die Hauptziele?</h1>
                <p>
                    Wir brauchen mehr <b>sichere und ausreichend breite Radwege</b>, auf denen Kinder und ältere Menschen
                    sicher und selbstständig durch die Stadt fahren können.
                </p>
                
                <p id = "highlight">
                    Dafür braucht es eine <b>räumliche Trennung</b> und Entflechtung <br/><b>von Rad- und Autoverkehr</b>.
                </p>
                
                <div className="float-container">
                    <img src = "./img/png/protected-bike-lane-icon.png" alt = "protected-bike-lane-picto"/>
                </div>
                
                <p>
                    In weiten Teilen des Göttinger Radverkehrsnetzes kann man nicht sicher fahren.
                    Zwischen den Stadtvierteln klaffen riesige Lücken.
                </p>
                <p id = "highlight">
                    Mit dem Radentscheid wollen wir die <br/> <b>Lücken verkleinern</b>.
                </p>
                {/* Gegenüberstellung Radnetze ergänzen */}
            </div>
            <SectionLine how="center"/>
            <div className="section">
                <h1>Wie werden diese Ziele erreicht?</h1>
                <p>Die Ziele werden durch die folgenden zentralen Maßnahmen erreicht:</p>
                <ul id = "highlight">
                    <li>
                        <b>Trennung</b> von Rad und Auto auf stark befahrenen Straßen.
                    </li>
                    <li>
                        <b>Protected Bike Lanes</b> als sichere und geschützte Räume für den Radverkehr.
                    </li>
                    <li>
                        Entwicklung von Konzepten für <b>Einbahnstraßen</b>.
                    </li>
                    <li>
                        Attraktive Gestaltung von <b>Fahrradstraßen</b>.
                    </li>
                </ul>
                <p id = "highlight">
                    <b>Protected Bike Lanes</b> trennen Straßen wie die Merkelstraße oder den Rosdorfer Weg in der Mitte auf:
                    Hier Fahrräder, dort Autos, dazwischen eine Barriere.
                </p>
                <p>So sieht das in Darmstadt aus:</p>
                <div className="float-container">
                <img src ="./img/png/protected-bike-lane-darmstadt.png"/>
                </div>
                <p>So können Menschen jeden Alters sicher und geschützt Rad fahren.</p>
            </div>
            <SectionLine how="center"/>
            <div className="section">
                <h4>Hier gehts weiter:</h4>
                <ul id="highlight">
                    <li><HashLink to = "../faq/#faqhelfen"><a>Unterstütze den Radentscheid.</a></HashLink></li>
                    <li><HashLink to="../faq#top"><a>Häufig gestellte Fragen (FAQ).</a></HashLink></li>
                    <li><HashLink to="../news#top"><a>Presse und Aktuelles.</a></HashLink></li>
                    <li><HashLink to="../#downloads"><a>Vollständiger Text der Begehren.</a></HashLink></li>
                </ul>
            </div>
        </div>
    )
}

export default Flyer;