import React, { lazy } from "react";
import { Link } from "react-router-dom";
import Button2 from "../../common/Button2";
import FullWidthContainer from "../../common/FullWidthContainer";
import YoutubeVideo from "../../components/YoutubeVideo";
import {HashLink} from "react-router-hash-link";
import { SectionLineIMG } from "../../components/SectionLine";

import Wahl from "../../components/AktuelleThemen/Reiter/WahlErgebnisse";

const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const IntroNeu = lazy(() => import("../../components/IntroNeu"));
const SectionLine = lazy(() => import("../../components/SectionLine"));
const SammelAufruf = lazy(() => import("../../components/SammelAufruf"));
const Supporter = lazy(() => import("../../components/Supporter"));
const Slideshow = lazy(() => import("../../components/Slideshow"));
const Dashboard = lazy(() => import("../../components/Dashboard"));
const AnnouncementTeaser = lazy(() => import("../../components/AnnouncementTeaser"));
const AktuelleThemen = lazy(() => import("../../components/AktuelleThemen"));
const Downloads = lazy(() => import("../../components/Downloads"));
const WegeZurUnterschrift = lazy(() => import("../../components/WegeZurUnterschrift"));
const FlyerStart = lazy(() => import("../../components/FlyerEyecatcher"));
const SoliTeaser = lazy(() => import("../../components/SoliTeaser"));
const Newsletter = lazy(() => import("../../components/Newsletter"));
const Wahlergebnis = lazy(() => import("../../components/Wahlergebnis"));
const Countdown = lazy(() => import("../../components/Countdown"));

const Home = () => {
    const plakatelist:string[] = [
            'img/wahlplakate/web-abgabe.png',
            'img/wahlplakate/web-auto.png',
            'img/wahlplakate/web-friedländer.png',
            'img/wahlplakate/web-kind.png'
        ];
    return (<>
        {/* <SoliTeaser/> */}
        <Container>
          <div style={{textAlign: "right", justifyContent: "right", width: "100%", display: "flex", marginTop: ".2em"}}>
              <img src={process.env.PUBLIC_URL + '/img/icons/uk.svg'} style={{height: "1em", marginRight: ".2em"}}/>
              <Link to="./en">english summary</Link>
          </div>
        </Container>
        <AnnouncementTeaser/>
        {/*<SoliTeaser/>*/}
        <IntroNeu language="de"/>

        {/* Scroll To Top Pfeil unten Rechts */}
        <ScrollToTop />

        <SectionLine how ="left"/>

        <Countdown/>

        <SectionLine how = "center"/>
        <Wahlergebnis/>


        {/* <WegeZurUnterschrift/> */}

        {/* <SectionLine how = "left"/>
        <FlyerStart/> */}

        {/* Aktuelle Themen */}
        <SectionLine how = "right"/>
        <AktuelleThemen/>

        <SectionLine how = "center"/>
        <Newsletter/>

        {/* Unterstützungsaufruf */}
        <SectionLine how = "center"/>
        <SammelAufruf/>

        {/* Slideshow Wahlplakate */}
        <SectionLine how = "right"/>
        <Slideshow images={plakatelist}/>

        <SectionLine how = "left"/>
        <SectionLineIMG how = "./img/intro_background/bikesharing_pixabay_lizenzfrei.jpg"/>
        <SectionLine how = "right"/>

        {/* Sektion Image Film */}
        <YoutubeVideo ytId={"sNvWThUery0"}/>

        {/* Dashboard aktueller Unterschriftenstand */}
        <SectionLine how = "center"/>
        <Dashboard lang="de"/>

        {/* Downloads */}
        <SectionLine how = "right"/>
        <Downloads/>

        <SectionLine how = "center"/>
        {/* Karte und Liste der Sammelstellen */}
        {/* <Sammelstellen/>
        <SectionLine how = "right"/> */}
        <div id = "downloads"/>


      {/* Unterstützende des Radentscheids */}
      <Supporter lang = "de"/>
      <SectionLine how = "center"/>

        {/* Schlussteil mit Weiterleitung FAQ */}
        <Container>
            <h1 style={{"textAlign":"center", "margin":"2em", "marginBottom":"0.5em"}}>Es sind noch Fragen offen geblieben?</h1>
            <div style = {{"display":"grid", "gridTemplateColumns":"1fr 1fr", "maxWidth":"600px","marginLeft":"auto", "marginRight":"auto", "marginBottom":"3em"}}>
                <HashLink to="./faq#top"><Button2 onClick = {() => {}} content = "Hier geht's zum FAQ!"/></HashLink>
                <HashLink to="./gute-gruende#top"><Button2 onClick = {() => {}} content = "100+ gute Gründe für den Radentscheid"/></HashLink>
            </div>
        </Container>
    </>);
};

export default Home;
