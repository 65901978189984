import React, {useState} from "react";
import './eingabe.css';

import {CatSlider, CustDropdown, NumSlider} from "../../../../common/Slider";
import Button2 from "../../../../common/Button2";

import { scrollTo } from "../../../../common/scrollTo";


export interface submitValueProps {
    alter: Number;
    verkehrsmittel: string;
    kinder: string;
    wohnort: string;
    arbeitsort: string;
}

interface EingabeProps {
    updateSubmitValue: React.Dispatch<React.SetStateAction<submitValueProps | undefined>>;
    updateSubmitted: React.Dispatch<React.SetStateAction<Number>>;
}


const Eingabe = ({updateSubmitValue, updateSubmitted}:EingabeProps) => {
    const [alter, updateAlter] = useState(35);
    const [verkehrsmittel, updateVerkehrsmittel] = useState('Fahrrad');
    const [kinder, updateKinder] = useState('Ja');
    const [wohnort, updateWohnort] = useState('Innenstadt');
    const [arbeitsort, updateArbeitsort] = useState('Stadtgebiet Göttingen');

    return (
        <div className="inputs">
            {/* Alter */}
            <div className="question">
                <p>Wie alt bist du? ({alter} Jahre)</p>
            </div>
            <div className="slider">
                <NumSlider max={99} min={1} onChange={updateAlter} value={alter}/>
            </div>
            
            {/* Mobilitätsverhalten */}
            <div className="question">
                <p>Welches Verkehrsmittel verwendest du am häufigsten?</p>
            </div>
            <div className="slider">
                <CatSlider
                    categories={['Fahrrad','Auto','Zu Fuß','Bus/ Bahn','E-Scooter','anderes']}
                    onChange={updateVerkehrsmittel} 
                    value={verkehrsmittel}
                />
            </div>
            
            {/* Kinder */}
            <div className="question">
                <p>Hast du Kinder?</p>
            </div>
            <div className="slider">
                <CatSlider onChange={updateKinder} categories={['Ja','Nein']} value={kinder}/>
            </div>
            
            {/* Wohnort */}
            <div className="question">
                <p>In welchem Stadtviertel/ Ortschaft wohnst du?</p>
            </div>
            <div className="slider">
                <CustDropdown 
                buttonLabel = "Wähle deinen Stadtbezirk ..."
                onChange={updateWohnort} 
                categories={
                        [
                            'Innenstadt',
                            'Oststadt',
                            'Südstadt',
                            'Weststadt',
                            'Nordstadt',
                            'Weende',
                            'Nikolausberg',
                            'Geismar',
                            'Grone',
                            'Herbershausen',
                            'Groß Ellershausen',
                            'Hetjershausen',
                            'Knutbühren',
                            'Elliehausen',
                            'Esebeck',
                            'Holtensen',
                            'Deppoldshausen',
                            'Roringen',
                            'außerhalb des Stadtgebiets'
                        ]
                        } value={wohnort}/>
            </div>
            
            {/* Arbeitsort */}
            <div className="question">
                <p>Liegt dein Arbeitsort/ Tätigkeitsstätte/ Schulort im Stadtgebiet?</p>
            </div>
            <div className="slider">
                <CatSlider 
                    onChange={updateArbeitsort} 
                    categories={
                        [
                            'Stadtgebiet Göttingen',
                            'Kreis Göttingen',
                            'außerhalb'
                        ]
                    } value={arbeitsort}/>
            </div>
            <div className="submitter">
                <Button2
                    content="Auswerten"
                    onClick={() => {
                        updateSubmitValue({
                            alter: alter,
                            arbeitsort: arbeitsort,
                            kinder: kinder,
                            verkehrsmittel: verkehrsmittel,
                            wohnort: wohnort
                        });
                        updateSubmitted(1);
                        scrollTo("aktuelle-themen");
                    }}
                />
            </div>
        </div>
    )
}

export default Eingabe;