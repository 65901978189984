import React, { useState } from "react";
import CustomAdv from "../../components/AktuelleThemen/Reiter/CustomAdv";
import FullWidthContainer from "../../common/FullWidthContainer";
import Container from "../../common/Container";
import { Link } from "react-router-dom";

const RadOMatPage = () => {
    return (
        <>
        <CustomAdv />

        <FullWidthContainer>
            <div style={{backgroundColor: "white", padding: "4em", marginTop: "1em", marginBottom: "1em"}}>
            <Container>
                <h1>Weiterführende Links:</h1>
                <ul style = {{listStyleImage: "url(https://radentscheid-goe.de/img/icons/G0_Logo_Pfeil.png)"}}>
                    <li><Link to = "../faq">Häufige Fragen (FAQ)</Link></li>
                    <li><Link to = "../flyer">Radentscheid in der Kurzfassung (Web-Flyer)</Link></li>
                    <li><Link to = "../news">Berichterstattung über den Radentscheid</Link></li>
                </ul>
            </Container>
            </div>
        </FullWidthContainer>
    </>
    );
}

export default RadOMatPage;