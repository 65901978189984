import React, { useState } from "react";
import "./KostenRadentscheid.css";
import Button2 from "../../../common/Button2";

interface AusklappenProps {
    heading: JSX.Element;
    content: JSX.Element;  
}
const Ausklappen = ({heading, content}:AusklappenProps) => {
    const [hidden, updateHidden] = useState<boolean>(true)
    const handleHidden = () => {
        hidden === true ? updateHidden(false) : updateHidden(true);
    }
    if (hidden === true){
        return(
            <>
        {heading}
        <a onClick={handleHidden}>mehr anzeigen</a>
        </>
    )
    } else {
        return(
        <>
            {heading}
            {content}
            <a onClick={handleHidden}>weniger anzeigen</a>
        </>
        )
    }
}



const KostenRadentscheid = () =>  {
    return (
        <div className="kosten-wrapper">
            <h3>Was kostet der Radentscheid wirklich?</h3>
            <img src = "./img/png/2024-04-24-radentscheid-kosten-summary.jpg"/>
            <h5>Das Fazit zuerst:</h5>
            <ul>
                <li>
                    Die Verlautbarungen der Verwaltungsspitze zu den Kosten des Radentscheids
                    im April 2024 unterscheiden sich deutlich von den Kostenangaben, 
                    die die Verwaltung uns im August 2023 übermittelt hat. 
                    Diese waren die Grundlage für die beiden Kostenschätzungen 
                    auf den Radentscheid-Formularen 
                    (39,4 Mio EUR und 56,4 Mio EUR, beides im Zeitrahmen bis 2030).
                </li>               
                <li>
                    Wir halten die Angabe "100 Mio EUR" für eine unseriöse und unverantwortliche 
                    Panikmache.
                    Wir bitten alle, diese von der Verwaltung seit Februar 2024 herausgegebenen
                    Zahlen zu hinterfragen.
                    Sie <i>widersprechen den eigenen Kostenschätzungen von August 2023</i>, 
                    und sie sind nicht plausibel.
                </li>
            </ul>
            <h5>Gründe, warum der Radentscheid die Stadt Göttingen insgesamt nur ca. 25 Mio.€ bis 2030 kostet:</h5>
            <Ausklappen 
                heading={<h6>1. Die Stadt hat sich in ihrer Kostenschätzung um 18 Mio. € verrechnet.</h6>}
                content={
                    <>         
                <p>
                    Der Stadt sind in ihrer Kostenberechnung mehrere Rechenfehler 
                    unterlaufen. 
                </p>
                <ul>
                    <li>
                    Beim Umbau der Bürgerstraße wurden Planungskosten 
                    und Preissteigerung fälschlich doppelt eingerechnet, 
                    was die Kosten des Radentscheids künstlich um 20 Mio. € erhöht. 
                    </li>
                    <li>
                        Weitere 2 Mio.€ werden dadurch falsch berücksichtigt,
                        dass Sach- und Personalkosten für das gesamte Jahr 2024 
                        angesetzt werden, die Bürgerentscheide aber erst im Juni 2024 stattfinden.
                    </li>
                    <li>
                    Es folgen weitere Fehler in der Zinseszinsrechnung im Kontext der Inflation -
                    hier allerdings zu einer Unterschätzung der Aufwendungen führend.
                    </li>
                </ul>
                <p>
                Nach einer Korrektur dieser fehlerhaften Kalkulation ist der 
                Radentscheid <b><u>bereits 18 Mio. € günstiger.</u></b>
                </p>

                </>}
            />
            <Ausklappen 
                heading={<h6>2. Die Kosten für beide Bürgerentscheide überschneiden sich</h6>}
                content={            
                <p>
                    Die Kosten für beide Bürgerentscheide können nicht einfach addiert werden.
                    Kosten für Fahrradstraßen und Protected Bike Lanes (Punkte 2.1 und 2.2) sind in den Kalkulationen
                    in beiden Bürgerentscheiden enthalten, was bei einer Zusammenfassung der Kosten zu berücksichtigen ist.
                    Die Höhe dieser Schnittmenge beläuft sich auf ca. 15% der kalkulierten Gesamtkosten.<br/>
                    Die einfache Addition der kalkulierten Kosten durch die Stadtverwaltung (Ursprung der 100 Mio. €)
                    der Begehren misachtet die Struktur der eigens erstellten Kalkulation. 
                </p>}
            />
            <Ausklappen 
                heading = {<h6>3. Es gibt 50 % Fördermittel von Land und Bund</h6>}
                content={
                    <p>
                        Investitionen in Radverkehrsinfrastruktur werden zu etwa 50-70 % 
                        von Land und Bund gefördert. Dieses Geld muss der städtische Haushalt 
                        nicht tragen. 
                        GöttingenZero hat sich in Berlin bei Fördermittelgebern erkundigt, 
                        die bestätigten, dass die Maßnahmen des Radentscheids aus 
                        Bundesmitteln mit 70 % förderfähig seien - 
                        die Stadt muss nur einen Antrag stellen.
                        Vereinzelte Landesprogrammme weisen noch deutlich höhere Förderquoten auf.
                    </p>
                }
            />
            <Ausklappen 
                heading={<h6>4. Inflation von 15% pro Jahr</h6>}
                content = {
                    <p>
                    Die Inflation wurde 2023 mit 15 % Baukostensteigerung pro Jahr durchgehend bis 2030 angesetzt.
                    Die Steigerungen bei den Baupreisindizes für Straßenbau lagen jahrelang bei etwa 5 % pro Jahr 
                    und sind 2022 mit den ausbleibenden russischen Gaslieferungen kurzzeitig auf 15 % gestiegen, und im 3. Quartal 2023 wieder gesunken. 
                    Im 1. Quartal 2024 wurden wieder 5 % ermittelt. <br/>
                    Quelle: <a href ="https://www.destatis.de/DE/Themen/Wirtschaft/Konjunkturindikatoren/Preise/bpr210.html#241662">Statistisches Bundesamt, April 2024</a>
                    <br/>
                    Mit 5 % anstatt 15 % Inflation liegen die städtischen Kosten für den Radentscheid bei 
                    25 Mio EUR verteilt auf 6 Jahre bis 2030. Das sind weniger als 5 Mio EUR pro Jahr.
                    </p>
                }
                />
            <Ausklappen
                heading = {
                    <h6>5. Die Angabe "20 neue Stellen notwendig" widerspricht der Kostenaufstellung</h6>
                }
                content = {
                    <p>
                        Dass die Verwaltungsspitze im April 2024 plötzlich von 20 Stellen spricht, wirkt unglaubwürdig und widerspricht ihrer eigenen Berechnung von 2023.
                        In der Kostenschätzung von August 2023 ermittelte die Verwaltung für Bürgerbegehren 1 einen Bedarf von 7,5 Personalstellen, für Begehren 2 waren es 8,5 Personalstellen.
                        Zusammen 16 Stellen, allerdings muss auch hier die Überschneidung von 
                        geschätzt 25 % berücksichtigt werden - macht in der Summe 12 Stellen. 
                        Sie verteilen sich auf Planung, Ausführung, Verkehrstechnik, Behörde und Bauhof.
                        Die in beiden Begehren geforderte Umgestaltung von Fahrradstraßen und Einrichtung von Protected Bike Lanes muss nicht doppelt ausgeführt werden. 
                    </p>
                }
            />
            <Ausklappen
                heading = {
                    <h6>6. Es gibt bereits einen Radverkehrs-Etat im Haushalt</h6>
                }
                content = {
                    <p>
                    Der Radverkehrs-Etat im Haushalt liegt seit längerem bei 2-3 Millionen Euro im Jahr - allerdings wurden daraus seit mehreren Jahren kaum Gelder abgerufen. 
                    Seit 2022 wurden gar keine Radverkehrsprojekte mehr umgesetzt. 
                    Das Geld wurde für andere Zwecke verwendet. Eigentlich hätte die Stadt das ansparen müssen.
                </p>
                }
            />
            <Ausklappen
                heading = {
                    <h6>7. Die Stadt plant selbst teure Radverkehrsprojekte</h6>
                }
                content = {
                    <p>
                    In der Vorlage zu <a href="https://www.goettingen.de/allris/vo020?VOLFDNR=1001714&refresh=false&TOLFDNR=1009811" target="_blank"
                    > TOP 21 der Ratssitzung vom 12.04.2024</a> lehnt die Verwaltung den im Radentscheid geforderten Umbau der Bürgerstraße 
                    (das ist das teuerste Projekt im Radentscheid) 
                    ab mit dem Argument, der Umbau der Weender Landstraße sei sinnvoller. 
                    Aufwand und Preis für beide Maßnahmen sind vergleichbar. <br/>
                    Die Verwaltungsvorlage zeigt, dass die Stadt genug Geld im Verkehrshaushalt hat, 
                    um so einen teuren Straßenumbau zu finanzieren. 
                </p>
                }
            />
            <Ausklappen
                heading = {<h6>8. Die Radentscheid-Kosten im Verhältnis zum Busverkehr</h6>}
                content = {
                    <p>
                    Der städtische Defizitausgleich für die GöVB läuft auf eine Größenordnung von etwa 30 Mio EUR/Jahr hinaus 
                    (Quelle: Baudezernent Frithjof Look, Umweltausschuss 27.02.2024). 
                    Bei 5 % Inflation läge die 6-Jahres-Summe alleine dafür bei 235 Mio EUR. Hinzu kommen sehr teure Investitionen in den Busbetriebshof und das Busdepot am Schützenanger, ohne Fördermittel, sowie Mittel für die Grunderneuerung von Bushaltestellen (2,5 Mio EUR in 2024, Quelle: Bauausschuss 07.12.2023). 
                    Zusammen etwa 300 Mio EUR bis 2030.<br/>
                    Der Radentscheid fordert von der Stadt Investitionen in Höhe von nur einem Zehntel dessen, was die Stadt für den Busverkehr veranschlagt.
                </p>
                }
            />
            <Ausklappen
                heading = {<h6>9. Die Radentscheid-Kosten im Verhältnis zum Autoverkehr</h6>}
                content = {
                    <p>
                    Die 2024 begonnen Projekte im Straßenvermögenerhaltungsprogramm behandeln ausschließlich Kfz-Fahrbahnen (kein einziger Radweg wird saniert) 
                    und belaufen sich auf 12,6 Mio EUR (Quelle: Bauausschuss 12.07.2023). <br/>
                    Bei 5 % Inflation ergäbe sich ein 6-Jahres-Summenwert von 100 Mio EUR bis 2030.
                    Alleine für die Unterhaltung der Kfz-Fahrbahnen wird mehr als 3mal so viel städtisches Geld ausgegeben 
                    wie für den Radentscheid gefordert. Hinzu kommen jedes Jahr weitere Investitionen in den Autoverkehr, wie etwa der Bau neuer Straßen in Neubaugebieten. 
                    Schon seit vielen Jahren wird fast der gesamte Etat für Straßen-Infrastruktur nur für den Autoverkehr ausgegeben. 
                </p>
                }
            />
            <Ausklappen
                heading = {
                    <h6>10. Was kostet die Trennung von Fahrrad und Auto auf Hauptstraßen?</h6>
                }
                content = {
                        <>
                        <p>
                            Die Hauptforderung des Radentscheids ist die Trennung von Fahrrad und Auto auf stark befahrenen Durchgangsstraßen:  
                        </p>
                        <ul>
                            <li>Trennung der Fahrbahnen, in der Mitte eine Leitschwelle.</li>
                            <li>Hier Autos, dort Fahrräder. Fahrräder fahren dann auch nicht auf dem Gehweg.</li>
                            <li>Wo der Platz nicht ausreicht, werden Einbahnstraßen gebildet.</li>
                            <li>Beispiele Friedländer Weg, Merkelstraße, Stettiner Straße und einige andere.</li>
                        </ul>
                        <p>Was soll daran so teuer sein?</p>
                        <p>Kosten für Leitschwellen (Quelle: Stadt Göttingen, Kostenschätzung, 18.08.2023): </p>
                        <p>
                            Lieferung gem. Herstellerangaben (45 €/St Leitschwelle + 11 €/m Leuchtbügel + 5 €/m Schraube + 2x35 €/10 m Leitboy + 2x35 €/10 m Endstücke), Montage inkl. x Faktor 2 
                            Summe für eine Einheit: 150 € <br/>
                            Gesamtkosten für Leitschwellen: 1500 m/a  zum Preis von  150 € pro Einheit   macht  225.000 € Gesamtpreis im ersten Jahr für 1500 m Strecke, in den nächsten 5 Jahren jeweils plus Inflation.
                        </p>
                        <p>
                            9 km Leitschwellen kosten somit knapp 2 Millionen Euro. Hinzu kommen die Rotmarkierungen und die Beschilderung. Macht etwa 3 Millionen EUR in 6 Jahren.
                        </p>
                        <p>
                            Kosten für Protected Bike Lanes (Quelle: Stadt Göttingen, Kostenschätzung, 18.08.2023):
                            Beispiele: 
                        </p>
                        <table>
                            <tbody>
                                <tr><td>Friedländer Weg/Düstere Eichen-Weg, Am Steinsgraben bis Dahlmannstraße, Einbahnstraße</td>  <td>1370 m</td><td>611.795,00 €</td></tr>
                                <tr><td>Merkelstraße/Dahlmannstraße, Am Steinsgraben bis Düstere-Eichen-Weg, Einbahnstraße</td>     <td>1290 m</td><td>466.686,00 €</td></tr>
                                <tr><td>Wörthstraße (Breslauer Straße bis Am Steinsgraben), Einbahnstraße</td>                      <td>480 m</td>   <td>161.410,00 €</td></tr>
                                <tr><td>Stettiner Straße (Lotzestraße bis Reinhäuser Landstraße) </td>                              <td>510 m</td><td>220.758,00 €</td></tr>
                                <tr><td>Danziger Straße (Reinhäuser Landstraße bis Geismar Landstraße) </td>                        <td>680 m</td><td>274.454,00 €</td></tr>
                                <tr><td>Rosdorfer Weg (Bürgerstraße bis Schiefer Weg), Einbahnstraße</td>                           <td>100 m</td><td>31.687,00 €</td></tr>
                            </tbody>
                        </table>
                        <p>
                            Das bedeutet, 1 Mio EUR für Friedländer Weg und Merkelstraße. Plus Anpassung der Linienbusführung in der Oststadt für 2 Mio EUR. 
                            Hinzu kommen etwa 65 % Planungs- und Personalkosten, macht 12 Mio EUR, minus 50 % Förderung und bei 5 % Inflation ergibt das für 9 km Protected Bike Lanes 
                            etwa 5-6 Mio EUR für den städtischen Haushalt in 6 Jahren.  
                        </p>
                        <p>
                            Die Hauptforderung des Radentscheids umzusetzen ist nicht sehr teuer. 
                            Es würde keinen Sinn geben, wenn das Anbringen von Leitschwellen und Schildern für Einbahnstraßen zig Millionen Euro kosten würde. 
                        </p>
                        </>
            }/>
            <Ausklappen
                heading = {<h6>11. Was ist dann am Radentscheid so teuer?</h6>}
                content = {            <p>
                    Der Umbau der Bürgerstraße ist mit Abstand das teuerste Projekt des 
                    Radentscheids. Von den bei 5 % Inflation geschätzten 49,5 Mio EUR für 
                    den gesamten Radentscheid (von denen etwa die Hälfte durch Fördermittel 
                    getragen wird) macht die Bürgerstraße einschließlich Personalkosten etwa 
                    24 Mio EUR aus. <br/>
                    Die restlichen 25 Mio EUR verteilen sich auf viele kleine Maßnahmen, wie 
                    Bodenwellen, Fahrbahnmarkierungen, Fahrbahnabsenkungen bei Einmündungen 
                    und Verkehrsschilder. <br/>
                    Der Radentscheid fordert keinen einzigen neuen Radweg, sondern 
                    konzentriert sich darauf, bestehenden Verkehrsraum in der Kernstadt, der 
                    bislang dem Autoverkehr zur Verfügung steht, für einen sicheren 
                    Fahrradverkehr umzuwidmen. Das sind hauptsächlich regulatorische 
                    Maßnahmen, die verhältnismäßig günstig sind.
                </p>}
            />
            <Ausklappen
                heading={<h5>Anhang</h5>}
                content={
                    <>
                        <h6>Begehren 1: Stadt Göttingen, Kostenschätzung, 18.08.2023:</h6>
                        <table>
                            <thead>
                                <tr><th>Posten</th><th>Preis im 1. Jahr</th><th>% der Gesamtkosten</th><th>von 39.400.000 €</th></tr>
                            </thead>
                            <tbody>
                                <tr><td>2.1 Fahrradstraßen</td><td>718.051 €</td><td>32,8 %</td><td>12.831.649 EUR</td>			</tr>
                                <tr><td>2.2 Protected Bike Lanes</td><td>470.180 €</td><td>21,5 %</td><td>8.410.989 EUR</td>			</tr>
                                <tr><td>3 Ampelkreuzungen</td><td>249.000 €</td><td>11,4 %</td><td>4.459.780 EUR</td>			</tr>
                                <tr><td>3.1 Einmündungen</td><td>240.829 €</td><td>11,0 %</td><td>4.303.297 EUR</td>			</tr>
                                <tr><td>3.2 Ampeln 	</td><td>48.400 €</td><td>2,2 %</td><td>860.659 EUR</td>		</tr>
                                <tr><td>4 Abstellanlagen</td><td>219.000 €</td><td>10,0 %</td><td>3.912.088 EUR</td>			</tr>
                                <tr><td>5 Wegweisung</td><td>6.695 €</td><td>0,3 %</td><td>117.362 EUR</td>			</tr>
                                <tr><td>Einmal anfallende Kosten</td><td>234.533 €</td><td>10,8 %</td><td>279.118 EUR</td>			</tr>
                                <tr><td>Summe gesamt</td><td>2.186.708 €</td><td>100,0 %</td><td></td>			</tr>
                                <tr><td>Summe plus Personal, mit 15 % Inflation</td><td>39.400.000 €*</td><td></td><td>39.400.000 EUR</td>			</tr>
                            
                            </tbody>
                        </table>
                        <p>
                            * - Summe wurde per e-mail im August 2023 berichtigt, in der Auflistung stand 30.872.275 €. 
                            Es wurde nicht mitgeteilt, woraus sich der Unterschied von 9 Mio EUR ergab. 
                            Wir schätzen diese Zahl 39,4 Mio EUR als glaubhaft ein - 
                            da diese Zahlen damals, anders als die Darstellungen der Oberbürgermeisterin nach Februar 2024, 
                            noch nicht politisch motiviert waren (mit dem Ziel, den Radentscheid zu verhindern).
                        </p>
                        <h6>Begehren 2: Stadt Göttingen, Kostenschätzung, 18.08.2023:</h6>
                        <table>
                            <thead>
                                <tr><th>Posten</th><th>Preis im 1. Jahr</th><th>% der Gesamtkosten</th><th>von 39.400.000 €</th></tr>
                            </thead>
                            <tbody>
                                <tr><td>1 Umbau Bürgerstraße </td><td>19.069.386 €</td><td>75,3 %</td><td>42.441.701 EUR</td></tr>
                                <tr><td>1 Umbau Schildweg</td><td>374.348</td><td>1,5 %</td><td>845.452 EUR</td></tr>
                                <tr><td>1 Konzept Kasseler Landstraße</td><td>30</td><td>0,1 %</td><td>56.363 EUR</td></tr>
                                <tr><td>1 Konzept Greitweg</td><td>30</td><td>0,1 %</td><td>56.363 EUR</td></tr>
                                <tr><td>2.1 Fahrradstraßen</td><td>411.538</td><td>1,6 %</td><td>901.815 EUR</td></tr>
                                <tr><td>2.2 Protected Bike Lanes</td><td>5.416.131 €</td><td>21,4 %</td><td>12.061.784 EUR </td></tr>
                                <tr><td>Summe gesamt</td><td>25.331.403 €</td><td>100,0 %</td><td>nan</td></tr>
                                <tr><td>Summe plus Personal, mit 15 % Inflation</td><td>56.363.481 €</td><td>nan</td><td>56.363.481 EUR</td></tr>
                            </tbody>
                        </table>
                        <h6>Überschneidungen von Begehren 1 und 2</h6>
                        <ul>
                            <li>2.2 Protected Bike Lanes: vollständig, 12,1 Mio EUR</li>
                            <li>2.1 Fahrradstraßen: 0,9 Mio EUR</li>
                        </ul>
                        <p>Summe Überschneidungen: 13 Mio EUR</p>                    
                    </>
                }
            />
            <div className="links">
            <a 
                href="./downloads/2024-03-07-goe-zero-radentscheid-kosten.pdf"
                target = "_blank"
                ><Button2 content="Download Brief an die Fraktionen im März 2024 (PDF)" onClick={() => {}} />
            </a>
            <a 
                href="./downloads/2024-04-18-goe-zero-radentscheid-kosten.pdf"
                target = "_blank"
                ><Button2 content="Aktuelle Stellungnahme (PDF)" onClick={() => {}} />
            </a>
            </div>
        </div>
    )
}

export default KostenRadentscheid;