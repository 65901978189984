import React from "react";
import { Row, Col } from "antd";
import { Fade } from "react-awesome-reveal";
import "./style.css";
import Container from "../../common/Container";

interface ContentProps {
    topic:string;
    img:string;
    text:string;
    url?:string | undefined;

}

const Item = ({topic, img, text, url}:ContentProps) => {
    if (typeof url === "undefined") {
        return(
            <div className = "column">
                <img src = {img} alt = "bild"/>
                <div className = "flex">
                    <img src = "./img/icons/G0_Logo_Pfeil.png" alt = ""/>
                    <h4>{topic}</h4>
                </div>
                <p>{text}</p>
            </div>
        )
    } else {
        return(
                <div className = "column">
                    <a href = {url} target = '_blank'>
                        <img id = 'linked' src = {img} alt = "bild"/>
                    <div className = "flex">
                        <img src = "./img/icons/G0_Logo_Pfeil.png" alt = ""/>
                        <h4>{topic}</h4>
                    </div>
                    </a>
                    <p>{text}</p>
                </div>
        )
    }

}


interface ListBlockImgProps {
    subject:string;
    id:string;
    content:ContentProps[];
}
const ListBlockImg = ({subject, id, content}:ListBlockImgProps) => {
    return(
        <Fade direction="right">
            <Container>
            <Row justify="space-between" align="middle" id={id}>
                <div className = "list-block-container">
                    <h1>{subject}</h1>
                    <div className = "grid">
                        {
                            content.map(
                                i => {
                                    if (typeof i.url === "undefined") {
                                        return(<Item topic = {i.topic} img = {i.img} text = {i.text}/>)
                                    } else {
                                        return(<Item topic = {i.topic} img = {i.img} text = {i.text} url = {i.url}/>)
                                    }
                                }
                            )
                        }
                    </div>
                </div>
            </Row>
            </Container>
        </Fade>
    )
}

export default ListBlockImg;