import React from "react";
import Container from "../../common/Container";
import ListBlockImg from "../ListBlockImg";



const DownloadsEn = () => {
    return(
        <Container>
                  
      <ListBlockImg
        subject = "Downloads"
        id = "downloads"
        content = {
          [
            {
                topic: "first referendum.",
                img: "./img/icons/begehren1-icon.jpg",
                text: "full text of the first referendum (general strategies)",
                url: "./downloads/2023-08-28-radentscheid-begehren-1.pdf"
            },
            {
                topic: "second referendum.",
                img: "./img/icons/begehren2-icon.jpg",
                text: "full text of the second referendum (concrete measures)",
                url: "./downloads/2023-08-28-radentscheid-begehren-2.pdf"
            },
            {
                topic: "map of all measures.",
                img: "./img/icons/karte-icon.png",
                text: "detailed map of all measures (source: Stadt Göttingen)",
                url: "./downloads/karte.pdf"
            },
            {
                topic: "election flyer.",
                img: "./img/icons/wahlkampf-flyer.png",
                text: "election flyer as PDF-document (german)",
                url: "./downloads/wahlkampf-flyer.pdf"
            },
            {
                topic: "flyer signature collection.",
                img: "./img/icons/radnetz-icon.jpg",
                text: "flyer signature collection as PDF-document (german)",
                url: "./downloads/2023-08-28-radentscheid-flyer.pdf"
            }
        ]
        }
      />
        </Container>
    );

} 

export default DownloadsEn;